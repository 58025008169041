/* TRANSACTIONS */
export const GET_TRANSACTIONS = "GET_TRANSACTIONS"
export const GET_TRANSACTIONS_SUCCESS = "GET_TRANSACTIONS_SUCCESS"
export const GET_TRANSACTIONS_FAIL = "GET_TRANSACTIONS_FAIL"

/**
 * add transaction
 */
export const ADD_NEW_TRANSACTION = "ADD_NEW_TRANSACTION"
export const ADD_TRANSACTION_SUCCESS = "ADD_TRANSACTION_SUCCESS"
export const ADD_TRANSACTION_FAIL = "ADD_TRANSACTION_FAIL"

/**
 * Edit transaction
 */
export const UPDATE_TRANSACTION = "UPDATE_TRANSACTION"
export const UPDATE_TRANSACTION_SUCCESS = "UPDATE_TRANSACTION_SUCCESS"
export const UPDATE_TRANSACTION_FAIL = "UPDATE_TRANSACTION_FAIL"

/**
 * Delete transaction
 */
export const DELETE_TRANSACTION = "DELETE_TRANSACTION"
export const DELETE_TRANSACTION_SUCCESS = "DELETE_TRANSACTION_SUCCESS"
export const DELETE_TRANSACTION_FAIL = "DELETE_TRANSACTION_FAIL"
