import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { ADD_NEW_PLAN, GET_PLANS, UPDATE_PLAN, DELETE_PLAN } from "./actionTypes"

import {
  getPlansSuccess,
  getPlansFail,
  addPlanSuccess,
  addPlanFail,
  updatePlanSuccess,
  updatePlanFail,
  deletePlanSuccess,deletePlanFail
} from "./actions"

//Include Both Helper File with needed methods
import { getPlans, addNewPlan, deletePlan } from "../../helpers/backend_helper"

function* fetchPlans() {
  try {
    const response = yield call(getPlans)
    if(response.status === true){
      yield put(getPlansSuccess(response.data))
    }else{
      yield put(getPlansFail(response.message))
    }
  } catch (error) {
    yield put(getPlansFail(error))
  }
}

function* onAddNewPlan({ payload: plan }) {

  try {
    const response = yield call(addNewPlan, plan)

    if(response.status === true){
      yield put(addPlanSuccess(response.data))
    }else{
      yield put(addPlanFail(response.message))
    }
  } catch (error) {
    yield put(addPlanFail(error))
  }
}

function* onUpdatePlan({ payload: plan }) {
  try {
    const response = yield call(addNewPlan, plan)
    if(response.status === true){
      yield put(updatePlanSuccess(response.data))
    }else{
      yield put(updatePlanFail(response.message))
    }
  } catch (error) {
    yield put(updatePlanFail(error))
  }
}

function* onDeletePlan({ payload: plan }) {
  try {
    const response = yield call(deletePlan, plan)
    if(response.status === true){
      yield put(deletePlanSuccess(response.data))
    }else{
      yield put(deletePlanFail(response.message))
    }
  } catch (error) {
    yield put(deletePlanFail(error))
  }
}

function* planSaga() {
  yield takeEvery(GET_PLANS, fetchPlans)
  yield takeEvery(ADD_NEW_PLAN, onAddNewPlan)
  yield takeEvery(UPDATE_PLAN, onUpdatePlan)
  yield takeEvery(DELETE_PLAN, onDeletePlan)
}

export default planSaga;
