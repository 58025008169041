import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
  Table
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";

//Lightbox
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import { Name, Email, Common, Price, PaymentScreenShot } from "./transactionListCol";

import {
  getTransactions as onGetTransactions,
  updateTransaction as onUpdateTransaction,
} from "store/transaction/actions";

import { isEmpty } from "lodash";

//redux
import { useSelector, useDispatch } from "react-redux";

const Transaction = props => {

  //meta title
  document.title = "Transaction List | Mini POS Pro";

  const dispatch = useDispatch();
  const [transaction, setTransaction] = useState();

  const [paymentScreenshot, setPaymentScreenshot] = useState();
  const [isFits, setisFits] = useState(false);

  const { transactions } = useSelector(state => ({
    transactions: state.Transaction.transactions,
  }));

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      status: (transaction && transaction.payment_status) || "",
      message: (transaction && transaction.transaction_info && transaction.transaction_info.message) || "",
    },
    validationSchema: Yup.object({
      status: Yup.string().required("Please select status"),
      message: Yup.string().required("Please enter message"),
    }),
    onSubmit: values => {
      var tmpTransaction = {
        "payment_orders_id": transaction.payment_orders_id,
        "transaction_info": {
          "id": transaction.utr_number == "" ? Date.now().toString() : transaction.utr_number,
          "message": values.message ?? "",
        },
        "payment_status": values.status,
        "payment_gateway": "upi"
      }

     
     
      dispatch(onUpdateTransaction(tmpTransaction));
      validation.resetForm();
      toggle();
    },
  });

  const [modal, setModal] = useState(false);

  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: () => {
          return <input type="checkbox" className="form-check-input" />;
        },
      },
      {
        Header: "Payment Screenshot",
        accessor: "payment_screenshot",
        filterable: true,
        Cell: cellProps => {
          return <PaymentScreenShot cell={cellProps} handleImageClick={() => {
            setPaymentScreenshot(process.env.REACT_APP_API_BASE_URL + "/" + cellProps.value);
            setisFits(true);
          }} />;
        },
      },
      {
        Header: "Payment Orders Id",
        accessor: "payment_orders_id",
        filterable: true,
        Cell: cellProps => {
          return <Common {...cellProps} />;
        },
      },
      {
        Header: "Transaction Id",
        accessor: "transaction_id",
        filterable: true,
        Cell: cellProps => {
          return <Common {...cellProps} />;
        },
      },
      {
        Header: "UTR Number",
        accessor: "utr_number",
        filterable: true,
        Cell: cellProps => {
          return <Common {...cellProps} />;
        },
      },
      {
        Header: "Plan",
        accessor: "plan_info.title",
        filterable: true,
        Cell: cellProps => {
          return <Common {...cellProps} />;
        },
      },
      {
        Header: "Type",
        accessor: "plan_info.type",
        filterable: true,
        Cell: cellProps => {
          return <Common {...cellProps} />;
        },
      },


      {
        Header: "Payment Gateway",
        accessor: "payment_gateway",
        filterable: true,
        Cell: cellProps => {
          return <Common {...cellProps} />;
        },
      },

      {
        Header: "Status",
        accessor: "payment_status",
        filterable: true,
        Cell: cellProps => {
          return <Common {...cellProps} />;
        },
      },

      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex align-items-center justify-content-center gap-3">
              {/* <Link
                to={"/user-details/" + cellProps.row.original.id.toString()}
                className="text-primary"

              >
                <i className="mdi mdi-eye font-size-18" id="viewtooltip" />
                <UncontrolledTooltip placement="top" target="viewtooltip">
                  View
                </UncontrolledTooltip>
              </Link> */}
              <Link
                to="#"
                className="text-success"
                onClick={() => {

                  const transactionData = cellProps.row.original;
                  handleTransactionClick(transactionData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    if (transactions && !transactions.length) {
      dispatch(onGetTransactions());
    }
  }, [dispatch, transactions]);



  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const handleTransactionClick = arg => {

    const transaction = arg;
    
    setTransaction({ ...transaction });
    toggle();
  };

  const toggle = () => {
    setModal(!modal);
  };

  const handlePlanClicks = () => {
    setPlan({});
    toggle();
  };



  const keyField = "id";

  return (
    <React.Fragment>
      
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Menu" breadcrumbItem="Transaction List" />


          {isFits ? (
            <Lightbox
              mainSrc={paymentScreenshot}
              enableZoom={false}
              onCloseRequest={() => {
                setPaymentScreenshot("");
                setisFits(!isFits);
              }}
            />
          ) : null}

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={transactions}
                    isGlobalFilter={true}
                    isAddRecordList={false}
                    // addNewRecordTitle="Create Plan"
                    // handleRecordClick={handlePlanClicks}
                    customPageSize={10}
                    className="custom-header-css"
                  />


                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {"Update Transaction Details"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                          <Col xs={12}>
                            <div className="mb-3">
                              <Table className="table-nowrap mb-0">
                                <tbody>
                                  <tr>
                                    <th scope="row">User ID :</th>
                                    <td>{(transaction && transaction.user_id) || ""}</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Payment Orders Id :</th>
                                    <td>{(transaction && transaction.payment_orders_id) || ""}</td>
                                  </tr>

                                  <tr>
                                    <th scope="row">Transaction Id :</th>
                                    <td>{(transaction && transaction.transaction_id) || ""}</td>
                                  </tr>

                                  <tr>
                                    <th scope="row">UTR Number :</th>
                                    <td>{(transaction && transaction.utr_number) || ""}</td>
                                  </tr>

                                  <tr>
                                    <th scope="row">Transaction Info :</th>
                                    <td style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>
                                      {
                                        (transaction === undefined || transaction.transaction_info === '')
                                          ? ""
                                          : Object.keys(transaction.transaction_info).map(key => (
                                            <> "{key}: {transaction.transaction_info[key]}"<br /></>
                                          ))
                                      }
                                    </td>
                                  </tr>

                                  <tr>
                                    <th scope="row">Plan Info :</th>
                                    <td style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>
                                      {
                                        (transaction === undefined || transaction.plan_info === '')
                                          ? ""
                                          : Object.keys(transaction.plan_info).map(key => (
                                            <> "{key}: {transaction.plan_info[key]}"<br /></>
                                          ))
                                      }
                                    </td>

                                  </tr>

                                </tbody>
                              </Table>

                              <div className="mb-3">

                                <Label className="form-label mt-2">Status</Label>
                                <Input
                                  name="status"
                                  type="select"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={
                                    validation.values.status || ""
                                  }
                                  invalid={
                                    validation.touched.status && validation.errors.status ? true : false
                                  }
                                >

                                  <option>success</option>
                                  <option>cancelled</option>

                                </Input>
                                {validation.touched.status && validation.errors.status ? (
                                  <FormFeedback status="invalid">{validation.errors.status}</FormFeedback>
                                ) : null}

                              </div>

                              <div className="mb-3">

                                <Label className="form-label">Message</Label>
                                <Input
                                  name="message"
                                  label="Message"
                                  placeholder="Enter Message"
                                  type="text"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.message || ""}
                                  invalid={
                                    validation.touched.message &&
                                      validation.errors.message
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.message &&
                                  validation.errors.message ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.message}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </div>

                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Save
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Transaction);
