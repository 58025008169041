import {
  GET_USERS_SUCCESS,
  GET_USERS_FAIL,
  ADD_USER_SUCCESS,
  GET_USER,
  GET_USER_SUCCESS,
  GET_USER_FAIL,
  UPDATE_USER_PLAN_FAIL,
  UPDATE_USER_PLAN_SUCCESS

} from "./actionTypes"

const INIT_STATE = {
  users: [],
  userProfile: {},
  error: null,
  loading: true,
  user: null,
}

const users = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
      }

    case GET_USERS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_USER:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case GET_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
        error: null
      }
    case GET_USER_FAIL:
      return {
        ...state,
        loading: false,
        user: null,
        error: action.payload
      }
    case UPDATE_USER_PLAN_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          plan_details: action.payload,
        }
      }
    case UPDATE_USER_PLAN_FAIL:
      return {
        ...state,
        error: action.payload
      }
    default:
      return state
  }
}

export default users
