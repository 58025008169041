import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";

//Dashboard 
import Dashboard from "./dashboard/reducer";

//Users 
import Users from "./users/reducer";

//Plan 
import Plan from "./plan/reducer";

//Transaction
import Transaction from "./transaction/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Dashboard,
  Users,
  Plan,
  Transaction
});

export default rootReducer;
