import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";

import { Name, Email, Common, Tags, Projects } from "./userlistCol";

import {
  getUsers as onGetUsers
} from "store/users/actions";

import {
  getPlans as onGetPlans,
} from "store/plan/actions";

import { isEmpty } from "lodash";

//redux
import { useSelector, useDispatch } from "react-redux";

const UsersList = props => {

  //meta title
  document.title = "User List | Mini POS Pro";

  const dispatch = useDispatch();
  
  const { users } = useSelector(state => ({
    users: state.Users.users,
  }));

 

  const [userList, setUserList] = useState([]);
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  

  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: () => {
          return <input type="checkbox" className="form-check-input" />;
        },
      },
      // {
      //   Header: "Img",
      //   // accessor: "name",
      //   disableFilters: true,
      //   filterable: true,
      //   accessor: (cellProps) => (
      //     <>
      //       {!cellProps.img ? (
      //         <div className="avatar-xs">
      //           <span className="avatar-title rounded-circle">
      //             {cellProps.name.charAt(0)}
      //           </span>
      //         </div>
      //       ) : (
      //         <div>
      //           <img
      //             className="rounded-circle avatar-xs"
      //             src={cellProps.img}
      //             alt=""
      //           />
      //         </div>
      //       )}
      //     </>
      //   ),
      // },
      {
        Header: "Name",
        accessor: "name",
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Email",
        accessor: "email",
        filterable: true,
        Cell: cellProps => {
          return <Email {...cellProps} />;
        },
      },
      {
        Header: "Country Code",
        accessor: "country_code",
        filterable: true,
        Cell: cellProps => {
          return <Common {...cellProps} />;
        },
      },
      {
        Header: "Mobile",
        accessor: "mobile",
        filterable: true,
        Cell: cellProps => {
          return <Common {...cellProps} />;
        },
      },
      {
        Header: "Shop Name",
        accessor: "shop_name",
        filterable: true,
        Cell: cellProps => {
          return <Common {...cellProps} />;
        },
      },
      {
        Header: "Shop Address",
        accessor: "shop_address",
        filterable: true,
        Cell: cellProps => {
          return <Common {...cellProps} />;
        },
      },
      {
        Header: "Shop Mobile Number",
        accessor: "shop_mobile_number",
        filterable: true,
        Cell: cellProps => {
          return <Common {...cellProps} />;
        },
      },
      // {
      //   Header: "Tags",
      //   accessor: "tags",
      //   filterable: true,
      //   Cell: cellProps => {
      //     return <Tags {...cellProps} />;
      //   },
      // },
      // {
      //   Header: "Projects",
      //   accessor: "projects",
      //   filterable: true,
      //   Cell: cellProps => {
      //     return (
      //       <>
      //         {" "}
      //         <Projects {...cellProps} />{" "}
      //       </>
      //     );
      //   },
      // },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to={"/user-details/"  +  cellProps.row.original.id.toString()}
                className="text-primary"

              >
                <i className="mdi mdi-eye font-size-18" id="viewtooltip" />
                <UncontrolledTooltip placement="top" target="viewtooltip">
                  View
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClick(userData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const userData = cellProps.row.original;
                  onClickDelete(userData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    if (users && !users.length) {
      dispatch(onGetUsers());
      setIsEdit(false);
    }
  }, [dispatch, users]);

  const { plans } = useSelector(state => ({
    plans: state.Plan.plans,
  }));

  useEffect(() => {
    if (plans && !plans.length) {
      dispatch(onGetPlans());
      
    }
  }, [dispatch, plans]);

  

  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };



  const keyField = "id";

  return (
    <React.Fragment>
      
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Menu" breadcrumbItem="User List" />

        


          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={users}
                    isGlobalFilter={true}
                    isAddUserList={false}
                    // handleUserClick={handleUserClicks}
                    customPageSize={10}
                    className="custom-header-css"
                  />

                 
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(UsersList);
