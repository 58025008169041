import axios from "axios";
import { del, get, post, put } from "./api_helper";
import * as url from "./url_helper";

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};


// Login Method
const postLogin = data => post(url.POST_LOGIN, data);

//Users
const getUsers = data => post(url.POST_USERS, data);
const getUser = data => post(url.POST_USER, data);
const updateUserPlan = data => post(url.POST_UPDATE_USER_PLAN, data);

//Plan
const getPlans = data => post(url.POST_PLAN, data);
const addNewPlan = data => post(url.POST_ADD_EDIT, data);
const deletePlan = data => post(url.POST_DELETE_PLAN, data);

//Transaction
const getTransactions = data => post(url.POST_TRANSACTIONS, data);
const updateTransaction = data => post(url.POST_UPDATE_TRANSACTIONS, data);


export {
  getLoggedInUser,
  isUserAuthenticated,
  postLogin,
  getUsers,
  getPlans,
  addNewPlan,
  deletePlan,
  getUser,
  updateUserPlan,
  getTransactions,
  updateTransaction
};
