import {
  GET_PLANS_SUCCESS,
  GET_PLANS_FAIL,
  ADD_PLAN_SUCCESS, ADD_PLAN_FAIL,
  UPDATE_PLAN_SUCCESS,
  UPDATE_PLAN_FAIL,
  DELETE_PLAN_SUCCESS,
  DELETE_PLAN_FAIL
} from "./actionTypes"

const INIT_STATE = {
  plans: [],
  error: {},
}

const plans = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PLANS_SUCCESS:
      return {
        ...state,
        plans: action.payload,
      }

    case GET_PLANS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_PLAN_SUCCESS:
      return {
        ...state,
        plans: [...state.plans, action.payload],
      }

    case ADD_PLAN_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_PLAN_SUCCESS:
      
      return {
        ...state,
        plans: state.plans.map(plan =>
          plan._id.toString() === action.payload._id.toString()
            ? { plan, ...action.payload }
            : plan
        ),
      }

    case UPDATE_PLAN_FAIL:
      return {
        ...state,
        error: action.payload,
    }
    case DELETE_PLAN_SUCCESS:
      return {
        ...state,
        plans: state.plans.filter(
          plan => plan._id.toString() !== action.payload.toString()
        ),
      }
    case DELETE_PLAN_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default plans
