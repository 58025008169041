/* USERS */
export const GET_USERS = "GET_USERS"
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS"
export const GET_USERS_FAIL = "GET_USERS_FAIL"

/* USER DETAILS */
export const GET_USER = "GET_USER"
export const GET_USER_SUCCESS = "GET_USER_SUCCESS"
export const GET_USER_FAIL = "GET_USER_FAIL"

/* UPDATE USER PLAN */
export const UPDATE_USER_PLAN = "UPDATE_USER_PLAN"
export const UPDATE_USER_PLAN_SUCCESS = "UPDATE_USER_PLAN_SUCCESS"
export const UPDATE_USER_PLAN_FAIL = "UPDATE_USER_PLAN_FAIL"

