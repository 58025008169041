import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_USERS, GET_USER_PROFILE , ADD_NEW_USER , DELETE_USER, UPDATE_USER, GET_USER, UPDATE_USER_PLAN } from "./actionTypes"

import {
  getUsersSuccess,
  getUsersFail,
  getUserSuccess,
  getUserFail,
  updateUserPlanSuccess,
  updateUserPlanFail,

} from "./actions"

//Include Both Helper File with needed methods
import { getUsers, getUser,updateUserPlan } from "../../helpers/backend_helper"

function* fetchUsers() {
  try {
    const response = yield call(getUsers)
    if(response.status === true){
      yield put(getUsersSuccess(response.data))
    }else{
      yield put(getUsersFail(response.message))
    }
  } catch (error) {
    yield put(getUsersFail(error))
  }
}

function* fetchUser({data}) {
  try {
    const response = yield call(getUser, data)
    if(response.status === true){
      yield put(getUserSuccess(response.data))
    }else{
      yield put(getUserFail(response.message))
    }
  } catch (error) {
    yield put(getUserFail(error))
  }
}

function* onUpdateUserPlan({data}) {
  try {
    const response = yield call(updateUserPlan, data)
    if(response.status === true){
      yield put(updateUserPlanSuccess(response.data))
    }else{
      yield put(updateUserPlanFail(response.message))
    }
  } catch (error) {
    yield put(updateUserPlanFail(error))
  }
}

function* usersSaga() {
  yield takeEvery(GET_USERS, fetchUsers)
  yield takeEvery(GET_USER, fetchUser)
  yield takeEvery(UPDATE_USER_PLAN, onUpdateUserPlan)
}

export default usersSaga;
