/* USERS */
export const GET_PLANS = "GET_PLANS"
export const GET_PLANS_SUCCESS = "GET_PLANS_SUCCESS"
export const GET_PLANS_FAIL = "GET_USERS_FAIL"

/**
 * add plan
 */
export const ADD_NEW_PLAN = "ADD_NEW_PLAN"
export const ADD_PLAN_SUCCESS = "ADD_PLAN_SUCCESS"
export const ADD_PLAN_FAIL = "ADD_PLAN_FAIL"

/**
 * Edit plan
 */
export const UPDATE_PLAN = "UPDATE_PLAN"
export const UPDATE_PLAN_SUCCESS = "UPDATE_PLAN_SUCCESS"
export const UPDATE_PLAN_FAIL = "UPDATE_PLAN_FAIL"

/**
 * Delete PLAN
 */
export const DELETE_PLAN = "DELETE_PLAN"
export const DELETE_PLAN_SUCCESS = "DELETE_PLAN_SUCCESS"
export const DELETE_PLAN_FAIL = "DELETE_USER_FAIL"
