import {
  GET_USERS,
  GET_USERS_FAIL,
  GET_USERS_SUCCESS,
  GET_USER_SUCCESS,
  GET_USER_FAIL,
  GET_USER,
  UPDATE_USER_PLAN,
  UPDATE_USER_PLAN_SUCCESS,
  UPDATE_USER_PLAN_FAIL,
} from "./actionTypes"

export const getUsers = () => ({
  type: GET_USERS,
})

export const getUsersSuccess = users => ({
  type: GET_USERS_SUCCESS,
  payload: users,
})


export const getUsersFail = error => ({
  type: GET_USERS_FAIL,
  payload: error,
})


export const getUser = data => ({
  type: GET_USER,
  data,
})

export const getUserSuccess = user => ({
  type: GET_USER_SUCCESS,
  payload: user,
})


export const getUserFail = error => ({
  type: GET_USER_FAIL,
  payload: error,
})

export const updateUserPlan = data => ({
  type: UPDATE_USER_PLAN,
  data,
})

export const updateUserPlanSuccess = planDetails => ({
  type: UPDATE_USER_PLAN_SUCCESS,
  payload: planDetails,
})

export const updateUserPlanFail = error => ({
  type: UPDATE_USER_PLAN_FAIL,
  payload: error,
})
