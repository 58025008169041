import {
  GET_TRANSACTIONS_SUCCESS,
  GET_TRANSACTIONS_FAIL,
  ADD_TRANSACTION_SUCCESS, 
  ADD_TRANSACTION_FAIL,
  UPDATE_TRANSACTION_SUCCESS,
  UPDATE_TRANSACTION_FAIL,
  DELETE_TRANSACTION_SUCCESS,
  DELETE_TRANSACTION_FAIL
} from "./actionTypes"

const INIT_STATE = {
  transactions: [],
  error: {},
}

const transaction = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        transactions: action.payload,
      }

    case GET_TRANSACTIONS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_TRANSACTION_SUCCESS:
      return {
        ...state,
        transactions: [...state.transactions, action.payload],
      }

    case ADD_TRANSACTION_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_TRANSACTION_SUCCESS:
      
      return {
        ...state,
        transactions: state.transactions.map(transaction =>
          transaction.payment_orders_id.toString() === action.payload.payment_orders_id.toString()
            ? { transaction, ...action.payload }
            : transaction
        ),
      }

    case UPDATE_TRANSACTION_FAIL:
      return {
        ...state,
        error: action.payload,
    }
    case DELETE_TRANSACTION_SUCCESS:
      return {
        ...state,
        transactions: state.transactions.filter(
          transaction => transaction.id.toString() !== action.payload.toString()
        ),
      }
    case DELETE_TRANSACTION_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default transaction
