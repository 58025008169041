import React, { useState, useEffect,useMemo } from "react";
import { Link } from 'react-router-dom';
import {
    Container,
    Row,
    Col,
    Alert,
    Modal,
    ModalHeader,
    ModalBody,
    Button,
    Label,
    Input,
    FormFeedback,
    Form,
    Spinner,
    Card,
    CardBody,
    CardTitle,
    Table
} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";
import withRouter from "components/Common/withRouter";


// TableContainer

// import { Pdate, Ddate, Name, Idno, Budget } from "./CryptoCol";

import TableContainer from "../../components/Common/TableContainer";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

import avatar from "../../assets/images/users/avatar-1.jpg";
// actions
import { editProfile, resetProfileFlag } from "../../store/actions";

import {
    getUser as onGetUser,
    getPlans as onGetPlans,
    updateUserPlan as onUpdateUserPlan
} from "../../store/actions";

const UserDetails = props => {

    const params = props.router.params;

    //meta title
    document.title = "User Details | Mini POS Pro";

    const dispatch = useDispatch();


    const [planModal, setPlanModal] = useState(false);
    const [isPlanEdit, setIsPlanEdit] = useState(false);

    const { user, error, loading, plans } = useSelector(state => ({
        user: state.Users.user,
        error: state.Users.error,
        loading: state.Users.loading,
        plans: state.Plan.plans,
    }));

    useEffect(() => {
        if (plans && !plans.length) {
            dispatch(onGetPlans());
            setIsPlanEdit(false);
        }
        if (params && params.id) {
            dispatch(onGetUser({
                "user_id": params.id
            }));
        }
    }, [dispatch]);


    const planToggle = () => {
        setPlanModal(!planModal);
    };

    const handleUpgradePlanClick = () => {

        setIsPlanEdit(true);
        planToggle();
    };

    if (!error) {

    } else {

    }


    // validation
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            planId: (user && user.plan_details && user.plan_details.id) || "",
        },
        validationSchema: Yup.object({
            planId: Yup.string().required("Please select plan type"),
        }),
        onSubmit: values => {
            const data = {
                "user_id": params.id,
                "plan_id": values.planId
            };

            dispatch(onUpdateUserPlan(data));
            validation.resetForm();
            planToggle();
        },
    });

    const columns = useMemo(
        () => [
          {
            Header: "#",
            accessor: "_id",
            disableFilters: true,
            filterable: true,
            Cell: cellProps => {
              return cellProps.value ? cellProps.value : '';
            },
          },{
            Header: "Name",
            accessor: "name",
            disableFilters: true,
            filterable: true,
            Cell: cellProps => {
              return cellProps.value ? cellProps.value : '';
            },
          }]);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumb title="User" breadcrumbItem="User Details" />

                    {loading ? <div className="text-center justify-content-center">

                        <Spinner color="primary" style={{
                            flex: 1,

                            justifyContent: 'center',
                            alignItems: 'center'
                        }} />

                    </div> :

                        <Row>
                            <Col xl="4">
                                <Card>
                                    <CardBody>
                                        <CardTitle className="mb-4">User Information</CardTitle>

                                        <div className="table-responsive">
                                            <Table className="table-nowrap mb-0">
                                                <tbody>
                                                    <tr>
                                                        <th scope="row">Name :</th>
                                                        <td>{user.name}</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Mobile :</th>
                                                        <td> {"+" + user.country_code + "-" + user.mobile}</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">E-mail :</th>
                                                        <td>{user.email}</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Shop Name :</th>
                                                        <td>{user.shop_name}</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Shop Address :</th>
                                                        <td>{user.shop_address}</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Shop Mobile Number :</th>
                                                        <td>{user.shop_mobile_number}</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Tax Number :</th>
                                                        <td>{user.tax_number}</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Currency Symbol :</th>
                                                        <td>{user.currency_symbol}</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                </Card>
                                <Card>
                                    <CardBody>
                                        <CardTitle className="mb-4">Plan Information</CardTitle>

                                        <div className="table-responsive">
                                            <Table className="table-nowrap mb-0">
                                                <tbody>
                                                    <tr>
                                                        <th scope="row">Title :</th>
                                                        <td>{(user.plan_details && user.plan_details.title) || ""}</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Type :</th>
                                                        <td>{(user.plan_details && user.plan_details.type) || ""}</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Days :</th>
                                                        <td>{(user.plan_details && user.plan_details.days) || ""}</td>
                                                    </tr>

                                                </tbody>
                                            </Table>
                                        </div>
                                        <div className="mt-4">
                                            <Link to="#" className="btn btn-soft-primary btn-hover w-100 rounded" onClick={handleUpgradePlanClick}>Upgrade</Link>
                                        </div>

                                        <Modal isOpen={planModal} toggle={planToggle}>
                                            <ModalHeader toggle={planToggle} tag="h4">
                                                Upgrade Plan
                                            </ModalHeader>
                                            <ModalBody>
                                                <Form
                                                    onSubmit={e => {
                                                        e.preventDefault();
                                                        validation.handleSubmit();
                                                        return false;
                                                    }}
                                                >
                                                    <Row>
                                                        <Col xs={12}>

                                                            <div className="mb-3">
                                                                <Label className="form-label">Plan</Label>
                                                                <Input
                                                                    name="planId"
                                                                    type="select"
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={
                                                                        validation.values.planId || ""
                                                                    }
                                                                    invalid={
                                                                        validation.touched.planId && validation.errors.planId ? true : false
                                                                    }
                                                                >

                                                                    {plans.map((item, key) => {
                                                                        return <option value={item._id.toString()} key={key}>{item.title}</option>
                                                                    })}


                                                                </Input>
                                                                {validation.touched.planId && validation.errors.planId ? (
                                                                    <FormFeedback status="invalid">{validation.errors.planId}</FormFeedback>
                                                                ) : null}
                                                            </div>

                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <div className="text-end">
                                                                <button
                                                                    type="submit"
                                                                    className="btn btn-success save-user"
                                                                >
                                                                    Save
                                                                </button>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </ModalBody>
                                        </Modal>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col xl="8">
                                <Row>
                                    <Col md="4">
                                        <Card className="mini-stats-wid">
                                            <CardBody>
                                                <div className="d-flex">
                                                    <div className="flex-grow-1">
                                                        <p className="text-muted fw-medium mb-2">Customers</p>
                                                        <h4 className="mb-0">{user.customers.length ?? 0}</h4>
                                                    </div>
                                                    <div className="mini-stat-icon avatar-sm align-self-center rounded-circle bg-primary">
                                                        <span className="avatar-title">
                                                            <i className={"bx bx-user font-size-24"} />
                                                        </span>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col md="4">
                                        <Card className="mini-stats-wid">
                                            <CardBody>
                                                <div className="d-flex">
                                                    <div className="flex-grow-1">
                                                        <p className="text-muted fw-medium mb-2">Category</p>
                                                        <h4 className="mb-0">{user.category.length ?? 0}</h4>
                                                    </div>
                                                    <div className="mini-stat-icon avatar-sm align-self-center rounded-circle bg-primary">
                                                        <span className="avatar-title">
                                                            <i className={"bx bx-customize font-size-24"} />
                                                        </span>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col md="4">
                                        <Card className="mini-stats-wid">
                                            <CardBody>
                                                <div className="d-flex">
                                                    <div className="flex-grow-1">
                                                        <p className="text-muted fw-medium mb-2">Product</p>
                                                        <h4 className="mb-0">{user.products.length ?? 0}</h4>
                                                    </div>
                                                    <div className="mini-stat-icon avatar-sm align-self-center rounded-circle bg-primary">
                                                        <span className="avatar-title">
                                                            <i className={"bx bx-shopping-bag font-size-24"} />
                                                        </span>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>


                                <Row>
                                    <Col md="4">
                                        <Card className="mini-stats-wid">
                                            <CardBody>
                                                <div className="d-flex">
                                                    <div className="flex-grow-1">
                                                        <p className="text-muted fw-medium mb-2">Units</p>
                                                        <h4 className="mb-0">{user.units.length ?? 0}</h4>
                                                    </div>
                                                    <div className="mini-stat-icon avatar-sm align-self-center rounded-circle bg-primary">
                                                        <span className="avatar-title">
                                                            <i className={"bx bx-grid-small font-size-24"} />
                                                        </span>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col md="4">
                                        <Card className="mini-stats-wid">
                                            <CardBody>
                                                <div className="d-flex">
                                                    <div className="flex-grow-1">
                                                        <p className="text-muted fw-medium mb-2">Taxs</p>
                                                        <h4 className="mb-0">{user.taxs.length ?? 0}</h4>
                                                    </div>
                                                    <div className="mini-stat-icon avatar-sm align-self-center rounded-circle bg-primary">
                                                        <span className="avatar-title">
                                                            <i className={"bx bx bxs-discount font-size-24"} />
                                                        </span>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col md="4">
                                        <Card className="mini-stats-wid">
                                            <CardBody>
                                                <div className="d-flex">
                                                    <div className="flex-grow-1">
                                                        <p className="text-muted fw-medium mb-2">Orders</p>
                                                        <h4 className="mb-0">{user.orders.length ?? 0}</h4>
                                                    </div>
                                                    <div className="mini-stat-icon avatar-sm align-self-center rounded-circle bg-primary">
                                                        <span className="avatar-title">
                                                            <i className={"bx bxs-shopping-bag-alt font-size-24"} />
                                                        </span>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                {/* <Card>
                <CardBody>
                  <CardTitle className="mb-4">My Projects</CardTitle>

                  <TableContainer
                    columns={columns}
                    data={user.products || []}
                    isGlobalFilter={false}
                    customPageSize={10}
                    customPageSizeOptions={true}
                  />
                </CardBody>
              </Card> */}

                            </Col>
                        </Row>}
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withRouter(UserDetails);
