import React, { useEffect, useState, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";

import { Name, Email, Common, Price, Projects } from "./planlistCol";

import {
  getPlans as onGetPlans,
  addNewPlan as onAddNewPlan,
  updatePlan as onUpdatePlan,
  deletePlan as onDeletePlan,
} from "store/plan/actions";

import { isEmpty } from "lodash";

//redux
import { useSelector, useDispatch } from "react-redux";

const PlanList = props => {

  //meta title
  document.title = "Plan List | Mini POS Pro";

  const dispatch = useDispatch();
  const [plan, setPlan] = useState();

  const { plans } = useSelector(state => ({
    plans: state.Plan.plans,
  }));

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      title: (plan && plan.title) || "",
      sub_title: (plan && plan.sub_title) || "",
      description: (plan && plan.description) || "",
      type: (plan && plan.type) || "free",
      days: (plan && plan.days) || "",
      plan_price: (plan && plan.plan_price) || "0",
      discount_price: (plan && plan.discount_price) || "0",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Title"),
      sub_title: Yup.string().required("Please Enter Sub Title"),
      description: Yup.string().required("Please Enter Description"),
      type: Yup.string().required("Please select plan type"),
      days: Yup.number().required("Please Enter days"),
      plan_price: Yup.number().required("Please Enter Plan Price"),
      discount_price: Yup.number().required("Please Enter Discount Price"),
     
    }),
    onSubmit: values => {
      debugger
      if (isEdit) {
        const updatePlan = {
          "discount_price": values.discount_price,
          "plan_price": values.plan_price,
          "type": values.type,
          "title": values.title,
          "sub_title": values.sub_title,
          "description": values.description,
          "days": values.days,
          "plan_id": plan._id

        };
        // update user
        dispatch(onUpdatePlan(updatePlan));
        setIsEdit(false);
        validation.resetForm();
      } else {
        const newPlan = {
          "discount_price": values.discount_price,
          "plan_price": values.plan_price,
          "type": values.type,
          "title": values.title,
          "sub_title": values.sub_title,
          "description": values.description,
          "days": values.days
        };
        // save new user
        dispatch(onAddNewPlan(newPlan));
        validation.resetForm();
      }
      toggle();
    },
  });



  const [userList, setUserList] = useState([]);
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const columns = useMemo(
    () => [
      {
        Header: "#",
        Cell: () => {
          return <input type="checkbox" className="form-check-input" />;
        },
      },

      {
        Header: "Title",
        accessor: "title",
        filterable: true,
        Cell: cellProps => {
          return <Name {...cellProps} />;
        },
      },
      {
        Header: "Sub title",
        accessor: "sub_title",
        filterable: true,
        Cell: cellProps => {
          return <Email {...cellProps} />;
        },
      },
      {
        Header: "Description",
        accessor: "description",
        filterable: true,
        Cell: cellProps => {
          return <Common {...cellProps} />;
        },
      },

      {
        Header: "Type",
        accessor: "type",
        filterable: true,
        Cell: cellProps => {
          return <Common {...cellProps} />;
        },
      },
      {
        Header: "Days",
        accessor: "days",
        filterable: true,
        Cell: cellProps => {
          return <Common {...cellProps} />;
        },
      },
      {
        Header: "Plan Price",
        accessor: "plan_price",
        filterable: true,
        Cell: cellProps => {
          return <Price {...cellProps} />;
        },
      },
      {
        Header: "Discount Price",
        accessor: "discount_price",
        filterable: true,
        Cell: cellProps => {
          return <Price {...cellProps} />;
        },
      },

      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {

                  const planData = cellProps.row.original;
                  handlePlanClick(planData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const planData = cellProps.row.original;
                  onClickDelete(planData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },
    ],
    []
  );

  useEffect(() => {

    if (plans && !plans.length) {
      dispatch(onGetPlans());
      setIsEdit(false);
    }

  }, [dispatch, plans]);



  var node = useRef();
  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const handlePlanClick = arg => {
    const plan = arg;

    setPlan({ ...plan });
    setIsEdit(true);

    toggle();
  };

  const toggle = () => {
    setModal(!modal);
  };

  const handlePlanClicks = () => {
    setPlan({});
    setIsEdit(false);
    toggle();
  };

  const [deleteModal, setDeleteModal] = useState(false);


  const handleDeletePlan = () => {
    if (plan && plan._id) {
      debugger
      dispatch(onDeletePlan({
        "plan_id":plan._id
      }));
    }
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const onClickDelete = plan => {
    setPlan({...plan});
    setDeleteModal(true);
  };



  const keyField = "id";

  return (
    <React.Fragment>
    <DeleteModal
            show={deleteModal}
            onDeleteClick={handleDeletePlan}
            deleteItemTitle="plan"
            onCloseClick={() => setDeleteModal(false)}
          />
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Menu" breadcrumbItem="Plan List" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={plans}
                    isGlobalFilter={true}
                    isAddRecordList={true}
                    addNewRecordTitle="Create Plan"
                    handleRecordClick={handlePlanClicks}
                    customPageSize={10}
                    className="custom-header-css"
                  />


                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit ? "Edit Plan" : "Add Plan"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                          <Col xs={12}>
                            <div className="mb-3">
                              <Label className="form-label">Title</Label>
                              <Input
                                name="title"
                                type="text"
                                placeholder="Insert title"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.title || ""}
                                invalid={
                                  validation.touched.title &&
                                    validation.errors.title
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.title &&
                                validation.errors.title ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.title}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">Sub Title</Label>
                              <Input
                                name="sub_title"
                                label="Sub Title"
                                placeholder="Insert Sub Title"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.sub_title || ""}
                                invalid={
                                  validation.touched.sub_title &&
                                    validation.errors.sub_title
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.sub_title &&
                                validation.errors.sub_title ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.sub_title}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">Description</Label>
                              <Input
                                name="description"
                                label="Description"
                                placeholder="Insert Description"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.description || ""}
                                invalid={
                                  validation.touched.description &&
                                    validation.errors.description
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.description &&
                                validation.errors.description ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.description}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">Days</Label>
                              <Input
                                name="days"
                                label="Days"
                                placeholder="Insert Days"
                                type="number"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.days || ""}
                                invalid={
                                  validation.touched.days &&
                                    validation.errors.days
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.days &&
                                validation.errors.days ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.days}
                                </FormFeedback>
                              ) : null}
                            </div>
                              <div className="mb-3">
                                            <Label className="form-label">Type</Label>
                                            <Input
                                                name="type"
                                                type="select"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={
                                                    validation.values.type || ""
                                                }
                                                invalid={
                                                    validation.touched.type && validation.errors.type ? true : false
                                                }
                                            >
                                                <option>free</option>
                                                <option>paid</option>
                                                
                                            </Input>
                                            {validation.touched.type && validation.errors.type ? (
                                                <FormFeedback status="invalid">{validation.errors.type}</FormFeedback>
                                            ) : null}
                                        </div>
                            <div className="mb-3">
                              <Label className="form-label">Plan Price</Label>
                              <Input
                                name="plan_price"
                                label="Plan Price"
                                placeholder="Insert Plan Price"
                                type="number"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.plan_price || ""}
                                invalid={
                                  validation.touched.plan_price &&
                                    validation.errors.plan_price
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.plan_price &&
                                validation.errors.plan_price ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.plan_price}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">Discount Price</Label>
                              <Input
                                name="discount_price"
                                label="Discount Price"
                                placeholder="Insert Discount Price"
                                type="number"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.discount_price || ""}
                                invalid={
                                  validation.touched.discount_price &&
                                    validation.errors.discount_price
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.discount_price &&
                                validation.errors.discount_price ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.discount_price}
                                </FormFeedback>
                              ) : null}
                            </div>
                           
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Save
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(PlanList);
