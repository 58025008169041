import React from "react";
import { Navigate } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";


// Dashboard
import Dashboard from "../pages/Dashboard/index";
import UsersList from "../pages/Users/index";
import Category from "pages/Category";
import PlansList from "../pages/Plan/index";
import UserDetails from "../pages/Users/user-details";
import Transaction from "../pages/Transaction";


const authProtectedRoutes = [
  // { path: "/dashboard", component: <Dashboard /> },

  { path: "/users-list", component: <UsersList /> },

  { path: "/category", component: <Category /> },

  { path: "/plans-list", component: <PlansList /> },

  { path: "/user-details/:id", component: <UserDetails /> },

  { path: "/transaction", component: <Transaction /> },
 
  {
    path: "/",
    exact: true,
    component: < Navigate to="/users-list" />,
  },
];

const publicRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },


];

export { authProtectedRoutes, publicRoutes };
