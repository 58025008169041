//LOGIN
export const POST_LOGIN = "/login";

//User
export const POST_USERS = "/users";
export const POST_USER = "/get_user";
export const POST_UPDATE_USER_PLAN = "/upgrade_plan_by_admin";

//Plan
export const POST_PLAN = "/plans";
export const POST_ADD_EDIT = "/add_edit_plan";
export const POST_DELETE_PLAN = "/delete_plan";

//Transaction
export const POST_TRANSACTIONS = "/get_transaction";
export const POST_UPDATE_TRANSACTIONS = "/update_transaction_status";


