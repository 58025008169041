import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { ADD_NEW_TRANSACTION, GET_TRANSACTIONS, UPDATE_TRANSACTION, DELETE_TRANSACTION } from "./actionTypes"

import {
  getTransactionsSuccess,
  getTransactionsFail,
  addTransactionSuccess,
  addTransactionFail,
  updateTransactionSuccess,
  updateTransactionFail,
  deleteTransactionSuccess,deleteTransactionFail
} from "./actions"

//Include Both Helper File with needed methods
import { getTransactions,  updateTransaction } from "../../helpers/backend_helper"

function* fetchTransactions() {
  try {
    const response = yield call(getTransactions)
    if(response.status === true){
      yield put(getTransactionsSuccess(response.data))
    }else{
      yield put(getTransactionsFail(response.message))
    }
  } catch (error) {
    yield put(getTransactionsFail(error))
  }
}


function* onUpdateTransaction({ payload: plan }) {
  try {
    const response = yield call(updateTransaction, plan)
    if(response.status === true){
      yield put(updateTransactionSuccess(response.data))
    }else{
      yield put(updateTransactionFail(response.message))
    }
  } catch (error) {
    yield put(updateTransactionFail(error))
  }
}

// function* onDeleteTransaction({ payload: plan }) {
//   try {
//     const response = yield call(deleteTransaction, plan)
//     if(response.status === true){
//       yield put(deleteTransactionSuccess(response.data))
//     }else{
//       yield put(deleteTransactionFail(response.message))
//     }
//   } catch (error) {
//     yield put(deleteTransactionFail(error))
//   }
// }

function* planSaga() {
  yield takeEvery(GET_TRANSACTIONS, fetchTransactions)
  // yield takeEvery(ADD_NEW_PLAN, onAddNewPlan)
  yield takeEvery(UPDATE_TRANSACTION, onUpdateTransaction)
  // yield takeEvery(DELETE_TRANSACTION, onDeleteTransaction)
}

export default planSaga;
